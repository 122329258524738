import GetConfig, { config } from '../../helpers/GetConfig';
import { gameIntroModal, gamePage, returningUserModal } from '../../types/pageNameContants';

interface EventProperties {
  event_category: string;
  send_to: string;
  event_label?: string;
}
const sendToComScore: (config: config) => void = ({ enableComScore }) => {
  if (enableComScore && window.COMSCORE) {
    window.COMSCORE.beacon({ c1: '2', c2: '8272010' });
    fetch('/pageview_candidate.txt');
  }
};

export const trackEvent: (category: string, action: string, label?: string) => void = (category, action, label) => {
  const { enableGoogleAnalytics, googleAnalyticsWebPropertyId } = GetConfig();
  console.log('trackEvent', category, action, label);
  if (window.gtag && enableGoogleAnalytics) {
    const properties: EventProperties = {
      event_category: category,
      send_to: googleAnalyticsWebPropertyId,
    };
    if (label) {
      properties['event_label'] = label;
    }
    window.gtag('event', action, properties);
  }
};

export const trackPageView: (pageTitle: string, pagePath: string, forcePageViewTrack?: boolean) => void = (
  pageTitle,
  pagePath,
  forcePageViewTrack
) => {
  const config = GetConfig();
  const gaEnabled = config.enableGoogleAnalytics;
  sendToComScore(config);
  if (window.gtag && gaEnabled) {
    const properties = {
      page_title: pageTitle,
      page_path: pagePath,
    };
    window.gtag('config', config.googleAnalyticsWebPropertyId, properties);

    if (
      forcePageViewTrack ||
      pageTitle === gamePage ||
      (pageTitle.includes('Modal') && pageTitle !== gameIntroModal && pageTitle !== returningUserModal)
    ) {
      const crossDomainGa4Properties = {
        page_title: pageTitle,
        page_location: `${config.appUrl.replace(/\/$/, '')}${pagePath}`,
        send_to: config.crossDomainGooglev4AnalyticsWebPropertyId,
      };
      window.gtag('event', 'page_view', crossDomainGa4Properties);
    }
  }
};
